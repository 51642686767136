<script setup lang="ts">
import { useFiltersStore } from '~/stores/filters.store';
import { useMetaStore } from '~/stores/meta.store';
import helper from '~/resources/js/libraries/helper';
import { ref } from 'vue';
import { useRoute, useRuntimeConfig } from 'nuxt/app';
import MobileListingCardFavorite from './partials/favorite-card.vue';
import MobileListingContact from './partials/listing-contact.vue';
import { showError } from 'nuxt/app';

const isOpen = ref(false);
const config = useRuntimeConfig();
const filtersStore = useFiltersStore();
const metaStore = useMetaStore();
const currentCurrency = helper.cookies.currency();
const { params, fullPath } = useRoute();
const { listing, breadcrumbs, meta } = await filtersStore.fetchListing(params.slug as any);
if (!listing) {
    showError({
        statusCode: 404,
        statusMessage: "Page Not Found",
        data: JSON.stringify({
            showSearch: true,
        }),
    });
}
const isShowMap = ref(false);
let lastSeenListings: any = helper.localStorage.getData('last-seen') ? JSON.parse(helper.localStorage.getData('last-seen')) : [];

if (lastSeenListings.length >= 10) {
    lastSeenListings.shift();
} else if (lastSeenListings.length && lastSeenListings.find((item: any) => item._id == listing._id)) {
    lastSeenListings = lastSeenListings.filter((item: any) => item._id != listing._id)
}

lastSeenListings.push(listing);
helper.localStorage.setData('last-seen', JSON.stringify(lastSeenListings));

metaStore.setMeta(meta);

const breadcrumbSeo = breadcrumbs?.map((b: any, i: number) => ({
    "@type": "ListItem",
    "@position": i + 1,
    "name": b.name,
    "item": `${config.public.websiteUrl}/${b.url}`
}))

const additionalPropertySeo = listing.features?.base_features?.map((feature: { [key: string]: string | number }) => ({
    "@type": "PropertyValue",
    "value": feature.value,
    "name": feature.name
}))

useSchemaOrg({
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    "itemListElement": breadcrumbSeo
})

useSchemaOrg({
    "@context": "http://schema.org/",
    "@type": "Product",
    "name": listing.title,
    "image": listing.picture,
    "description": listing.description,
    "offers": {
        "@type": "Offer",
        "url": `${config.public.websiteUrl}${fullPath}`,
        "priceCurrency": currentCurrency.code,
        "availability": "http://schema.org/InStock",
        "price": listing.sale_price,
        "seller": {
            "@type": "Organization",
            "name": listing.shop.name
        },
        "hasMerchantReturnPolicy": {
            "@type": "MerchantReturnPolicy",
            "applicableCountry": "tr",
            "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",
            "merchantReturnDays": 0,
            "returnMethod": "https://schema.org/ReturnByMail",
            "returnFees": "https://schema.org/FreeReturn"
        }
    }
})

useSchemaOrg(
    {
        "@context": "http://schema.org/",
        "@type": "Residence",
        "address": {
            "@type": "PostalAddress",
            "addressCountry": listing.country_name,
            "addressRegion": listing.city_name
        },
        "mainEntityOfPage": `${config.public.websiteUrl}/${fullPath}`,
        "url": `${config.public.websiteUrl}/${fullPath}`,
        "name": listing.title,
        "additionalProperty": additionalPropertySeo
    },
)

</script>
<template>
    <LayoutAppHead></LayoutAppHead>
    <NuxtLayout name="mobile-base">
        <template #mobile-header>
            <mobile-header>
                <template #header-left>
                    <mobile-back-button></mobile-back-button>
                </template>
                <template #header-right>
                    <mobile-listing-card-favorite :listingId="listing._id"></mobile-listing-card-favorite>
                    <mobile-share-button></mobile-share-button>
                </template>
            </mobile-header>
        </template>

        <div class="px-4 mt-4">
            <lightbox :images="listing.gallery_pictures" displayStyle="mobile" :arrows="true" />

            <div class="" v-if="listing.features">
                <div class="flex items-center opacity-60 text-black gap-5 text-base my-4">
                    <div class="flex flex-col items-center gap-1" v-for="feature in listing.features.base_features"
                        :key="feature.slug">
                        <div class="h-10 w-10 leading-10 text-center bg-gray-300 rounded-full">
                            <i :class="`${feature.icon} text-gray-900`"></i>
                        </div>
                        <span>
                            {{ feature.label_value || feature.value }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="my-3 border-b">
                <h2 class="text-black text-2xl font-bold my-2">{{ listing.title }}</h2>
                <template v-if="listing?.location?.lat && listing?.location?.lng">
                    <button @click="isShowMap = !isShowMap" type="button"
                        class="bg-indigo-200 text-indigo-900 rounded-lg my-3 p-2.5 border border-white">
                        <i class="fa-solid fa-location-dot"></i>
                        <span class="text-base ms-1">View on Map</span>
                    </button>
                    <shared-map-i-frame v-if="isShowMap" :lng="listing.location.lng" :lat="listing.location.lat"
                        addMark />
                </template>
                <div v-html="listing.description"></div>
                <!-- <span class="text-indigo-900 text-base my-2 block cursor-pointer">Read More</span> -->
            </div>

            <div class="my-3 border-b">
                <h4 class="text-xl text-black mb-3">{{ $t('client.property_information') }}</h4>
                <div class="flex flex-col gap-4">
                    <div class="flex gap-3 border-b pb-3">
                        <span class="w-1/3">{{ $t('client.type') }}</span>
                        <span class="font-medium">{{ listing.category_name }}</span>
                    </div>
                    <div class="flex gap-3 border-b pb-3">
                        <span class="w-1/3">{{ $t('client.ref_code') }}</span>
                        <span class="font-medium">#{{ listing._id }}</span>
                    </div>
                    <div class="flex gap-3 pb-3">
                        <span class="w-1/3">{{ $t('client.listing_date') }}</span>
                        <span class="font-medium">{{ listing.listing_date }}</span>
                    </div>
                </div>
            </div>

            <div class="my-3 border-b">
                <h4 class="text-xl text-black mb-3">{{ $t('client.features') }}</h4>
                <template v-for="feature in listing.features.multi_features">
                    <h5 class="text-base text-black mb-2 font-medium">{{ feature.name }}</h5>
                    <ul class="*:w-1/2 flex flex-wrap">
                        <li class="flex gap-2 mb-2 text-gray-600" v-for="sub_feature in feature.value">
                            <i class="fa-solid fa-check m-0.5"></i>
                            <span class="text-sm">{{ sub_feature.value }}</span>
                        </li>
                    </ul>
                </template>
            </div>
            
            <div class="w-full" v-if="listing.video">
                <video width="800" height="800" class="mx-auto" controls>
                    <source :src="listing.video" type="video/mp4">
                </video>
            </div>
            
            <div v-if="listing.shop" class="my-3 border-b">
                <h4 class="text-xl text-black mb-3">{{ $t('client.listing_provided_by') }}</h4>
                <div class="flex gap-4 pb-3">
                    <img :src="`${listing.shop.logo}?w=300&h=300`" :alt="listing.shop.name" class="rounded-lg h-auto w-24 object-cover">
                    <div class="flex flex-col">
                        <h3 class="opacity-80 text-black text-base font-medium">{{ listing.shop.name }}</h3>
                        <p class="opacity-80 text-black text-sm mt-1">
                            {{ $t('client.agent') }}: <span>{{ `${listing.user.first_name} ${listing.user.last_name}` }}</span>
                        </p>
                    </div>
                </div>
            </div>


            <div v-if="listing.similar_listings" class="my-3 border-b">
                <h4 class="text-xl text-black mb-3">{{ $t('client.similar_listings') }}</h4>
                <div class="my-2">
                    <div class="flex gap-2 mb-3" v-for="similar in listing.similar_listings">
                        <div class="w-60 h-36 object-fill block">
                            <NuxtLink :to="localePath({ name: 'listings.show', params: { slug: similar.url } })">
                                <img :src="`${similar.picture}?w=300&h=300`" :alt="similar.title" class="rounded-lg h-36 w-60 object-cover">
                            </NuxtLink>
                        </div>
                        <div class="flex flex-col gap-2 w-full">
                            <div class="flex justify-between items-center">
                                <h2 class="opacity-80 text-black text-xl font-bold">
                                    <NuxtLink :to="localePath({ name: 'listings.show', params: { slug: similar.url } })">
                                        {{ currentCurrency.code }}
                                        {{ helper.formatPrice(similar.sale_price) }}
                                    </NuxtLink>
                                </h2>
                                <mobile-listing-card-favorite :listingId="similar._id"></mobile-listing-card-favorite>
                            </div>
                            <h3 class="opacity-60 text-black text-xs">
                                <NuxtLink :to="localePath({ name: 'listings.show', params: { slug: similar.url } })">
                                    {{ similar.cities.join('/') }}
                                </NuxtLink>
                            </h3>

                            <div class="flex items-center opacity-60 text-black gap-8 text-base mb-2">
                                <div class="flex flex-col items-center gap-1" v-for="feature in similar.features">
                                    <i :class="feature.icon"></i>
                                    <span>{{ feature.value.label_value || feature.value }}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template #mobile-footer>
            <div class="fixed bottom-0 bg-white p-4 w-full">
                <div class="flex items-center justify-between gap-3 w-full">
                    <h3 class="text-xl font-bold text-indigo-900">
                        {{ currentCurrency.code }}
                        {{ helper.formatPrice(listing.sale_price) }}
                    </h3>
                    <div class="w-1/2 ms-auto flex items-center justify-end">
                        <mobile-listing-contact :listing="listing"></mobile-listing-contact>
                        <NuxtLink v-if="listing.user.show_whatsapp_in_listings && listing.user.formatted_whatsapp" :to="listing.whatsapp_message"
                            class="px-6 text-center border border-white py-2.5 rounded-lg bg-[#daebe2] text-[#28b06e]">
                            <i class="fa-brands fa-whatsapp fa-lg"></i>
                        </NuxtLink>
                    </div>
                </div>
            </div>
        </template>
    </NuxtLayout>
</template>